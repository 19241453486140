import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import "./src/styles/global.css";
import { LocaleProvider } from "./src/context/LocaleContext"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {
  return <LocaleProvider>{element}</LocaleProvider>;
};
