import * as React from "react";
import { useState } from "react";

import enLocaleData from "../locale/en";
import idLocaleData from "../locale/id";

type Props = {
  children: React.ReactNode;
};
type LocaleContext = {
  locale: string;
  toggleLocale: () => void;
  messages: any;
};

const localeData: any = {
  en: enLocaleData,
  id: idLocaleData,
};

export const LocaleContext = React.createContext<LocaleContext>(
  {} as LocaleContext
);

const LocaleProvider: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState("id");
  const [messages, setMessages] = useState(localeData["id"]);
  const toggleLocale = () => {
    if (!locale) {
      setLocale("id");
    }
    if (locale === "en") {
      setLocale("id");
    } else if (locale === "id") {
      setLocale("en");
    }
  };

  React.useEffect(() => {
    setMessages(localeData[locale]);
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, toggleLocale, messages }}>
      {children}
    </LocaleContext.Provider>
  );
};

export { LocaleProvider };
